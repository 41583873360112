const tableParams = {
    //记录数据
    listData: [] ,
    //表格加载等待状态
    loading: false ,
    //显示页的大小
    pagesize:20 , 
    //当前页
    pageNumber:1 ,
    //记录总数
    total: 0,
}

const dialogParams = {
    //表单标题宽度
    formLabelWidth: "100px" ,
    //dialog显示状态
    dialogFormVisible: false  ,
    //dialog标题
    dialogTitle: '新增' ,
    //1 新增
    add: 1 ,
    //2 更新
    update: 2 
}

dialogParams.dialogType = dialogParams.add ;

export { tableParams ,dialogParams }