import Vue from 'vue'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.css'
import App from './App.vue'
import router from './router'
import {resetRouter} from './router'

import { postRequest, getRequest, putRequest, deleteRequest } from './utils/api'
import { initMenu } from './utils/menus'

Vue.config.productionTip = false

Vue.prototype.postRequest = postRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.ResetRouter = resetRouter ;

Vue.use(ElementUI)

router.beforeEach((to, from, next) => {

  if(!sessionStorage.getItem("token") && to.path != '/login'){
    if(from.path !== null && from.path !== '/login'){
      window.sessionStorage.setItem('historyUrl' , to.fullPath) ;
    }
    router.push("/login")
    return ;
  }

  if(window.sessionStorage.getItem('token')){
    initMenu(store, router);  
  }

  next() ;
})

var app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

