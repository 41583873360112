<template>
  <div>
    <el-form ref="loginform" :rules="loginFormRules" :model="loginForm" class="loginContainer" size="small">
      <h5 class="loginTitle">系统登录</h5>
      <el-form-item prop="username">
        <el-input v-model="loginForm.username" placeholder="登录用户名"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="loginForm.password" type="password" placeholder="登录密码"></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input v-model="loginForm.code" placeholder="验证码" size="small"
          style="width: 230px;margin-right: 5px;"></el-input>
        <img :src="captchaUrl" @click="captchaUrl = '/api/captcha?time=' + (new Date()).getTime()"
          style="vertical-align:middle;height:30px;" />
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" style="width: 100%;" @click="doLogin('loginform')">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'Login',
  data() {
    return {
      captchaUrl: '/api/captcha?time=' + (new Date()).getTime(),
      rememberme: false,
      loginForm: {
        username: '',
        password: '',
        code: ''
      },
      loginFormRules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },
  mounted(){
    this.clearLoginInfo() ;
  } ,
  created(){
  },
  methods: {
    /**
     * 清除系统登录信息
     */
    clearLoginInfo(){
      if(window.sessionStorage.getItem("token")){
        //删除token
        window.sessionStorage.removeItem('token');
        //删除用户信息
        window.sessionStorage.removeItem('userinfo');
        //删除菜单信息
        this.$store.commit("initRoutes", []); 
        //重置路由信息
        this.ResetRouter();
      }
  
    } ,
    doLogin(refname) {
      
      this.clearLoginInfo() ;
      
      this.$refs[refname].validate((valid) => {
        if (valid) {
          this.postRequest('/user/login', this.loginForm).then(
            success => {
              if (success && success.code == 200) {
                window.sessionStorage.setItem('token', success.data.prefix + success.data.token);
                //获取用户信息
                  this.getRequest('/user/getCurrentUser', null).then(response => {
                    if (response && response.code == 200 && response.data) {
                      window.sessionStorage.setItem('userinfo', JSON.stringify(response.data));
                    }
                    let redirectUrl = window.sessionStorage.getItem("historyUrl");
                    if(redirectUrl && redirectUrl!='/'){
                      window.sessionStorage.removeItem("historyUrl") ;
                      this.$router.push(redirectUrl) ;
                    }else{
                      this.$router.push("/home") ;
                    }
                  })
              }
            }, error => {

            })
        } else {
          this.$message.error("请输入所有数据")
        }
      })
    }
  }
}
</script>

<style>
.loginContainer {
  width: 330px;
  margin: 100px auto;
  padding: 5px 30px 5px 30px;
  border-radius: 15px;
  border: 1px solid #efefef;
  box-shadow: 0 0 25px #cac8c8;
}

.loginTitle {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.rememberme {
  margin-bottom: 10px;
  margin-top: 0px;
}
</style>