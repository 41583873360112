import {Message} from 'element-ui'
import { getRequest } from './api';

export const initMenu = (store , router)=>{

  if(store.state.routes.length>0){
    
    return ;
  }

  getRequest('/system/menu/getListByLoginUser',null).then((result) => {
      if(result && result.data && Array.isArray(result.data)){

        //格式化菜单路由数据
        let routes = formatMenu(result.data)

        //将菜单路由信息缓存到vuex中
        store.commit('initPermissions' , result.data) ;
        store.commit("initRoutes",routes) ;
       
        //将菜单保存在sessionStorage
        //数据添加到路由
        router.addRoutes(store.state.routes) ;
      }
  }).catch((err) => {
    Message.error({message:"获取系统菜单失败"}) ;
  });
};
export const formatMenu = (menuData)=>{
    let routers = [] ;
    menuData.forEach(menu => {
      let {
        hidden,
        icons,
        menutype,
        name,
        parentid,
        path,
        url,
        component,
        children
      } = menu ;

      if(children && Array.isArray(children)){
        children = formatMenu(children) ;
      }

      let router ={
        name:name,
        path:path ,
        hidden:hidden ,
        icons:icons,
        menutype:menutype ,
        children:children,
        component:resolve=>{
          require(["../views" + component + ".vue"] , resolve) ;
        }
      }

      routers.push(router) ;

    });

    return routers ;
}