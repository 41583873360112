import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router' 



//响应业务代码
const respCode = {
    200: msg =>{
        if(msg){
            Message.success({message:msg}) ;
        }
    } ,
    1000: msg =>{
        if(msg){
            Message.error({message:msg}) ;
          }
    } ,
    1001: msg => {
      if(msg){
        Message.error({message:msg}) ;
      }else{
        Message.error({message:"未登录或登录过期，请先登录。"}) ;
      }
      sessionStorage.removeItem('token')
      router.push({path: '/login'}) ;
    },
    1002: msg => {
        if(msg){
            Message.error({message:msg}) ;
          }
    }
}

const httpStatusCode = {
    401:()=>{
        Message.error({message:'未登录系统，请先登录。'}) ;
        window.sessionStorage.setItem('historyUrl' , router.currentRoute.fullPath) ;
        router.push({path: '/login'}) ;
    } ,
    403:()=>{
        Message.error({message:'权限不足，请联系管理员'}) ;
    } ,
    404:()=>{
        Message.error({message:'页面不存在'}) ;
    } ,
    500:()=>{
        Message.error({message:'服务内部错误,请稍后再重试'}) ;
    }
} ;

//请求拦截器
axios.interceptors.request.use(request=>{
    if(window.sessionStorage.getItem('token')){
        request.headers['Authorization'] = window.sessionStorage.getItem('token') ; 
    }
    return request;
}) ;

//响应拦截器
axios.interceptors.response.use(response=>{

    //响应头如果携带刷新后的token ，则替换sessionStorage中的token
    if(response.headers['refresh_token']){
        window.sessionStorage.setItem('token' , response.headers['refresh_token'])
    }

    const { code , msg } = response.data ;

    if(code) {
        respCode[code]?.(msg)
    }

    return response.data;

},error=>{

    const { status } = error.response ;
    httpStatusCode[status]?.() ;

}) ;

let baseURL = "" ;
let apiPath = '/api' ;
//post请求
export const postRequest = (url , params)=>{
    params = params || [] ;
    return axios({
        baseURL: `${baseURL}` ,
        method: 'post' ,
        url: `${apiPath}${url}` ,
        data: params
    }) ;
}

//get请求
export const getRequest = (url , params)=>{
    return axios({
        baseURL:`${baseURL}` ,
        method:'get' ,
        url: `${apiPath}${url}` ,
        params: params
    }) ;
}
//put请求
export const putRequest = (url , params)=>{
    return axios({
        baseURL:`${baseURL}` ,
        method:'put' ,
        url: `${apiPath}${url}` ,
        data: params
    }) ;
}
//delete
export const deleteRequest = (url , params)=>{
    return axios({
        baseURL:`${baseURL}` ,
        method:'delete' ,
        url: `${apiPath}${url}` ,
        params: params
    }) ;
}