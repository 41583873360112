<template>
    <div class="homeContainer">
        <el-container>
            <el-header class="header">
                <span>ICKB防伪码管理系统</span>
                <div class="userinfo">
                    <el-dropdown @command="handleCommand" style="height: 40px;margin-top: 18px;padding-bottom: 0px;">
                        <span class="el-dropdown-link">
                            {{ user.username }}
                            <el-avatar :size="30" src="" @error="errorHandler" style="vertical-align:text-bottom ;">
                                <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
                            </el-avatar>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="usercenter">个人中心</el-dropdown-item>
                            <el-dropdown-item command="changePassword">修改密码</el-dropdown-item>
                            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>

                </div>
            </el-header>
            <el-container>
                <el-aside width="202px">
                    <h5>系统菜单</h5>
                    <el-menu unique-opened router default-active="2" @open="handleOpen" class="el-menu-vertical-demo"
                        @close="handleClose" background-color="#f9fbf3" text-color="#000" active-text-color="#ffd04b">
                        <template v-for="(item, index) in routes">
                            <el-submenu :index="index + ''" v-if="item.menutype == 0" style="text-align: left;">
                                <template slot="title">
                                    <i :class="item.icons" v-if="item.icons" style="margin-right: 3px;"></i>
                                    <span>{{ item.name }}</span>
                                </template>
                                <el-menu-item :index="children.path" v-if="!(children.hidden || children.menutype==2)" :key="children.id" v-for="(children, indexj) in item.children"
                                    style="padding-left:35px">
                                    <i :class="children.icons" v-if="item.icons" style="margin-right: 1px;"></i>
                                    {{ children.name }}
                                </el-menu-item>
                            </el-submenu>
                            <el-menu-item :index="item.path" v-else-if="item.hidden != true && item.url != ''">
                                <i :class="item.icons" v-if="item.icons"></i>
                                <span slot="title">{{ item.name }}</span>
                            </el-menu-item>
                        </template>
                    </el-menu>
                </el-aside>
                <el-main>
                    <div class="breadcrumb">
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: '/home' }"
                                v-if="this.$router.currentRoute.path != '/home'">首页</el-breadcrumb-item>
                            <el-breadcrumb-item v-if="this.$router.currentRoute.path != '/home'">{{ this.$router.currentRoute.name }}</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div class="main-body">
                        <div class="homeContainer" v-if="this.$router.currentRoute.path == '/home'">
                            <h3>欢迎进入防伪码管理系统</h3>
                        </div>
                        
                        <router-view></router-view>
                    </div>

                </el-main>
            </el-container>
        </el-container>
        <el-dialog title="修改密码" :visible.sync="dialogParams.dialogFormVisible" :close-on-click-modal="false" size="mini">
            <el-form :rules="changeUserPasswordRules" ref="changeUserPasswordForm" :model="changeUserPasswordForm" size="mini"  style="padding-right:30px;">
                <el-form-item label="旧密码" prop="oldpassword" :label-width="dialogParams.formLabelWidth">
                    <el-input type="password" placeholder="请输入旧密码" v-model="changeUserPasswordForm.oldpassword" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="password" :label-width="dialogParams.formLabelWidth">
                    <el-input type="password" placeholder="请输入密码" v-model="changeUserPasswordForm.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="enterpassword" :label-width="dialogParams.formLabelWidth">
                    <el-input type="password" placeholder="请输入确认密码" v-model="changeUserPasswordForm.enterpassword" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogParams.dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleUpdateUserPassword">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {dialogParams} from '@/utils/common' 


export default {
    name: 'Home',
    data() {
        return {
            changePasswordDialogVisiable: false,
            tableHeight: '0px' ,
            user: {},
            changeUserPasswordForm: {
                id: 0 ,
                oldpassword: '' ,
                password: '' ,
                enterpassword: ''
                
            },
            dialogParams,
            changeUserPasswordRules: {
                oldpassword: [
                    {required: true, message:'请输入旧密码', trigger: 'blur'}
                ] ,
                password: [
                    {required: true, message:'请输入密码', trigger: 'blur'}
                ] ,
                enterpassword: [
                    {required: true, message:'请输入确认密码', trigger: 'blur'} ,
                    {validator: (rule , value , callback)=>{

                        if(value != this.changeUserPasswordForm.password){
                            callback(new Error('两次密码不一致')) ;
                        }else{
                            callback() ;
                        }

                    } , trigger: 'blur'}
                ]
            },
            currentRoutePath: this.$router.currentRoute.path
        }
    },
    computed: {
        routes() {
            return this.$store.state.routes;
        }
    },
    watch: {
        $route: function(newValue , oldValue){

            this.$nextTick(()=>{
                this.updateTableHeight() ;
            })
        }
    } ,
    beforeCreate(){
        let userInfo = window.sessionStorage.getItem('userinfo') ;
        if(userInfo === null || userInfo === undefined) {
            this.$router.push("/login") 
        }
        this.user = JSON.parse(userInfo) 
    } ,
    mounted(){
        this.updateTableHeight() ;
    },
    methods: {
        updateTableHeight(){
            let paginationMarginTop = 15 ;
            // 文档高度
            let bodyHeight = document.querySelector('body')?.getBoundingClientRect().height??0;

            //表格顶部工具条高度
            let paginationHeight = document.querySelector(".pageContainer")?.getBoundingClientRect().height??0 ;      
            //表格位置顶部
            let mytableTop = document.querySelector(".mytable")?.getBoundingClientRect().top??0 ;
            //表格高度
            this.tableHeight = (bodyHeight - mytableTop - paginationHeight - paginationMarginTop) + "px" ;
        } ,
        handleOpen(key, keyPath) {
            //console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            //console.log(key, keyPath);
        },
        errorHandler() {
            return true
        },
        handleCommand(command) {
            //退出登录
            if (command == 'logout') {
                this.$confirm(`当前操作将退出系统登录，是否继续？`, '退出提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //删除token
                    window.sessionStorage.removeItem('token');
                    //删除用户信息
                    window.sessionStorage.removeItem('userinfo');
                    //删除菜单信息
                    this.$store.commit("initRoutes", []);
                    //跳转登录页面
                    this.$router.replace("/");
                }).catch(() => {

                })
            }else if(command == 'changePassword'){
                //修改密码
                dialogParams.dialogFormVisible = true ;
            }
        },
        /**
         * 提交个修改密码
         */
        handleUpdateUserPassword(){
            this.changeUserPasswordForm.id = this.user.id ;
            let that = this ;
            this.$refs['changeUserPasswordForm'].validate((valid)=>{
                if(valid){
                    this.postRequest('system/user/changeUserPassword',this.changeUserPasswordForm).then(resp=>{
                        dialogParams.dialogFormVisible = !(resp?.code == 200);

                        setTimeout(() => {
                            that.$router?.push("/") ;
                        }, 2000);
                    })
                }
            })
        }
    }
}
</script>

<style>
.homeContainer {
    display: flex;
    flex-grow: 1 1;
    height: 100%;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.homeContainer h3{
    margin: 30px auto;
    font-size: 50px;
    text-shadow: 1px 3px 5px #539ae3;
}
.el-header,
.el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
    height: 60px;
}

.el-aside {
    background-color: #f9fbf3;
    color: #333;
    text-align: center;
    line-height: 200px;
    border-right: 1px solid #e5e1e1;
}

.el-aside h5 {
    line-height: 200%;
    font-weight: 600;
    background-color: #f9fbf3;
    color: rgb(27, 27, 27);
    margin: 10px auto;
    font-size: 16px;
}

.el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: left;
    line-height: 140%;
    padding: 10px 1px 0px 10px;
    height: 100%;
}
.el-main .main-bodyContainer {
    border: 1px solid #fdfdfd;
    box-sizing: border-box;
    background-color: #fdfdfd;
    padding: 5px;
    box-shadow: 0px 0px 15px #d3d4d5;
    margin: 0px 10px 0px 0px;
    height: 100%;
}

.el-main .breadcrumb {
    flex: 0 0 auto;
    margin: 0px 5px 10px 0px;
    padding: 5px 0px 5px 5px;
    height: 20px;
    box-sizing: border-box;
}
.el-main .main-body{
    height: calc( 100% - 35px );
}

.el-container {
    flex: 1;
    height: 100%;
}

.el-menu {
    border-right-width: 0px;
}

.el-menu i {
    color: #5a9adb;
}

.header {
    text-align: left;
    background-color: #539ae3;
    display: flex;
}

.header>span {
    font-size: 24px;
    display: inline-block;
    text-shadow: 2px 1px 3px #36628f;
    color: #82b4e7;
}

.userinfo {
    text-align: right;
    flex: auto;
    box-sizing: border-box;

}

.el-dropdown-link {
    cursor: pointer;
    color: #fff;
}

.el-icon-arrow-down {
    font-size: 12px;
}
.el-table th.head-cell{
    background-color: #f3f8fb;
}
.mytable{
    height: v-bind(tableHeight);
    width:100%;
    margin-bottom: 5px;
}
</style>