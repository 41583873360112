import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


const store = new Vuex.Store({
    state: {
        routes: [],
        permissions: []
    },
    mutations: {
        initRoutes(state, data) {
            state.routes = data;
        },
        initPermissions(state , data){
            state.permissions = data ;
        }
    }
})

export default store ;