import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import Home from '@/views/Home'

Vue.use(VueRouter)


const baseroutes = [
  {
    path: '/login',
    name: '登录',
    component: Login,
    hidden: true 
  },
  {
    path: '/home' ,
    name: '首页' ,
    component: Home 
  }
]

const router = new VueRouter({
  //mode: 'history' ,
  routes: baseroutes
})

export const resetRouter = ()=>{
  router.matcher = new VueRouter({routes:baseroutes}).matcher ;
}

export default router
